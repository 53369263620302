<template>
  <div class="container p-2">
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <h1>Tavsifi</h1>
        <el-row>
          <b-card-text>Tovar tavsifini to'liq kiriting</b-card-text>
          <quill-editor
            v-model="description"
            :options="snowOption"
          />
        </el-row>
        <hr>
        <h1>{{ title }}</h1>
        <el-row :gutter="20">
          <el-col :span="11">
            <b-form-group label-for="product_category_characteristic_id">
              <validation-provider
                #default="{ errors }"
                name="product_category_characteristic_id"
                rules="required"
              >
                <v-select
                  id="product_category_characteristic_id"
                  v-model="
                    characteristicForm.product_category_characteristic_id
                  "
                  :state="errors.length > 3 ? false : null"
                  :options="characteristicsList"
                  :reduce="(option) => option.id"
                  label="name_uz"
                  @search="searchProduct"
                  @input="selectedProduct"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
          </el-col>
          <el-col :span="11">
            <b-form-group label-for="value">
              <validation-provider
                #default="{ errors }"
                name="value"
                rules="required"
              >
                <b-form-input
                  id="value"
                  v-model="characteristicForm.value"
                  :state="errors.length > 3 ? false : null"
                  name="value"
                  placeholder=""
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
          </el-col>
          <el-col :span="2">
            <b-button
              size="sm"
              variant="outline-primary mr-1"
              @click="addCharacteristic"
            >
              <feather-icon
                icon="PlusIcon"
                size="12"
              />
            </b-button>
          </el-col>
        </el-row>
        <!-- TABLE -->
        <table class="table">
          <thead>
            <tr>
              <th>№</th>
              <th>Xarakteristikasi</th>
              <th>Qiymati</th>
              <th />
            </tr>
          </thead>
          <tbody v-if="characteristics.length">
            <tr
              v-for="(item, index) in characteristics"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td width="400">
                <span v-if="!item.isEdit">{{
                  item.product_category_characteristic.name_uz
                }}</span>
                <b-form-group
                  v-else
                  label-for="product_category_characteristic_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="product_category_characteristic_id"
                    rules="required"
                  >
                    <v-select
                      id="product_category_characteristic_id"
                      v-model="item.product_category_characteristic_id"
                      :state="errors.length > 3 ? false : null"
                      :options="characteristicsList"
                      :reduce="(option) => option.id"
                      label="name_uz"
                      @search="searchProduct"
                      @input="
                        selectedProduct(
                          item.product_category_characteristic_id,
                          index
                        )
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                <span v-if="!item.isEdit">{{ item.value }}</span>
                <b-form-group
                  v-else
                  label-for="value"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="value"
                    rules="required"
                  >
                    <b-form-input
                      id="value"
                      v-model="item.value"
                      :state="errors.length > 3 ? false : null"
                      name="value"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </td>
              <td>
                <template v-if="item.isEdit">
                  <b-button
                    size="sm"
                    variant="outline-success"
                    @click="item.isEdit = false"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="12"
                    />
                  </b-button>
                </template>
                <template v-else>
                  <feather-icon
                    color="#FF0000"
                    icon="TrashIcon"
                    @click="deleteProduct(index)"
                  />
                  <feather-icon
                    color="#7367F0"
                    icon="EditIcon"
                    class="ml-1"
                    @click="edit(item)"
                  />
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form>
      <b-button
        class="mt-5 float-right"
        variant="primary"
        @click="save"
      >
        Сохранить
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { debounce } from 'debounce'
import { BCardText } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    BCardText,
  },
  props: {
    characteristicsInfoList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      characteristicsList: [],
      characteristicsEditList: [],
      characteristics: [],
      snowOption: {
        theme: 'snow',
      },
      characteristicForm: {
        product_category_characteristic_id: null,
        isEdit: false,
        product_category_characteristic: { name_uz: '' },
        value: '',
      },

      filterCharacter: {
        name_uz: '',
      },
      description: '',
      title: '',
      required,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      partiesInfo: 'parties/GET_ITEM',
      product_categories: 'productCategory/GET_ITEMS',
    }),
    itemId() {
      return this.$route.params.id
    },
    isShow() {
      return !!(this.$route.name === 'warehouseParty-show')
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {
    $route(newVal) {
      if (newVal) {
        this.showAction()
      }
    },
  },
  mounted() {
    this.title = 'Xarakteristika'
    this.fetchCharacters()
    if (this.isShow) {
      this.showAction()
    }
    if (this.characteristicsInfoList && this.characteristicsInfoList.length) {
      this.characteristics = this.characteristicsInfoList.filter(
        el => !el.is_description,
      )
      this.description = this.characteristicsInfoList.find(
        el => el.is_description,
      ).value
    }
  },
  methods: {
    async save() {
      if (this.description) {
        const formData = {
          product_category_id: this.itemId,
          characteristics: this.characteristics,
          description: this.description,
        }
        this.method(formData)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('save', true)
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },

    method(data) {
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    fetchCharacters() {
      this.getCharacteristics(this.filterCharacter).then(res => {
        this.characteristicsList = res.data.data
      })
    },
    edit(item) {
      const element = this.characteristicsList.find(
        el => el.id == item.product_category_characteristic_id,
      )
      this.getCharacteristics({ name_uz: element.name_uz }).then(res => {
        this.characteristicsEditList = res.data.data
      })
      item.isEdit = true
    },

    async addCharacteristic() {
      const valid = await this.validationForm()
      if (valid) {
        this.characteristics.push(this.characteristicForm)
        this.characteristicForm = {
          product_category_characteristic_id: null,
          isEdit: false,
          product_category_characteristic: { name_uz: '' },
          value: '',
        }
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    showAction() {
      this.showItem(this.itemId).then(res => {
        this.form.id = this.partiesInfo.id
      })
    },
    deleteProduct(index) {
      this.characteristics.splice(index, 1)
    },
    searchProduct: debounce(function (search) {
      this.filterCharacter.name_uz = search
      if (search && search.length >= 2) {
        this.fetchCharacters()
      }
    }, 500),
    selectedProduct(itemId) {
      const item = this.characteristicsList.find(el => el.id == itemId)
      this.characteristicForm.product_category_characteristic.name_uz = item.name_uz
    },
    ...mapActions({
      getCharacteristics: 'productCategory/productCategoryCharacteristics',
      getCharacteristicsValue: 'productCategory/getCharacteristicsValue',
      storeItem: 'productCategory/storeCharacteristicsValue',

      showItem: 'parties/show',
    }),
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
