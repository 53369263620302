var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container p-2"},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('h1',[_vm._v("Tavsifi")]),_c('el-row',[_c('b-card-text',[_vm._v("Tovar tavsifini to'liq kiriting")]),_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('hr'),_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":11}},[_c('b-form-group',{attrs:{"label-for":"product_category_characteristic_id"}},[_c('validation-provider',{attrs:{"name":"product_category_characteristic_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"product_category_characteristic_id","state":errors.length > 3 ? false : null,"options":_vm.characteristicsList,"reduce":function (option) { return option.id; },"label":"name_uz"},on:{"search":_vm.searchProduct,"input":_vm.selectedProduct},model:{value:(
                  _vm.characteristicForm.product_category_characteristic_id
                ),callback:function ($$v) {_vm.$set(_vm.characteristicForm, "product_category_characteristic_id", $$v)},expression:"\n                  characteristicForm.product_category_characteristic_id\n                "}})]}}],null,true)})],1)],1),_c('el-col',{attrs:{"span":11}},[_c('b-form-group',{attrs:{"label-for":"value"}},[_c('validation-provider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 3 ? false : null,"name":"value","placeholder":""},model:{value:(_vm.characteristicForm.value),callback:function ($$v) {_vm.$set(_vm.characteristicForm, "value", $$v)},expression:"characteristicForm.value"}})]}}],null,true)})],1)],1),_c('el-col',{attrs:{"span":2}},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary mr-1"},on:{"click":_vm.addCharacteristic}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}})],1)],1)],1),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("№")]),_c('th',[_vm._v("Xarakteristikasi")]),_c('th',[_vm._v("Qiymati")]),_c('th')])]),(_vm.characteristics.length)?_c('tbody',_vm._l((_vm.characteristics),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{attrs:{"width":"400"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(item.product_category_characteristic.name_uz))]):_c('b-form-group',{attrs:{"label-for":"product_category_characteristic_id"}},[_c('validation-provider',{attrs:{"name":"product_category_characteristic_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"product_category_characteristic_id","state":errors.length > 3 ? false : null,"options":_vm.characteristicsList,"reduce":function (option) { return option.id; },"label":"name_uz"},on:{"search":_vm.searchProduct,"input":function($event){return _vm.selectedProduct(
                        item.product_category_characteristic_id,
                        index
                      )}},model:{value:(item.product_category_characteristic_id),callback:function ($$v) {_vm.$set(item, "product_category_characteristic_id", $$v)},expression:"item.product_category_characteristic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[(!item.isEdit)?_c('span',[_vm._v(_vm._s(item.value))]):_c('b-form-group',{attrs:{"label-for":"value"}},[_c('validation-provider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 3 ? false : null,"name":"value","placeholder":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('td',[(item.isEdit)?[_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){item.isEdit = false}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"12"}})],1)]:[_c('feather-icon',{attrs:{"color":"#FF0000","icon":"TrashIcon"},on:{"click":function($event){return _vm.deleteProduct(index)}}}),_c('feather-icon',{staticClass:"ml-1",attrs:{"color":"#7367F0","icon":"EditIcon"},on:{"click":function($event){return _vm.edit(item)}}})]],2)])}),0):_vm._e()])],1),_c('b-button',{staticClass:"mt-5 float-right",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }