<template>
  <section class="app-ecommerce-details">
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop' }"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>
    <!-- Content -->
    <span
      class="link"
      @click="back"
    > Назад </span>
    <b-card
      v-if="product"
      class="mt-2"
      no-body
    >
      <b-card-body>

        <div>
          <ProductImageUpload />
        </div>
        <!--  -->
        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >
            <b-form-group
              :label="$t('ФИО')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :state="errors.length > 3 ? false:null"
                  name="name"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Логин')"
              label-for="login"
            >
              <validation-provider
                #default="{ errors }"
                name="login"
                rules="required"
              >
                <b-form-input
                  id="login"
                  v-model="form.login"
                  :state="errors.length > 3 ? false:null"
                  name="login"
                  :placeholder="$t('Логин')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Роль')"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="role_id"
                rules="required"
              >
                <b-form-select
                  id="role"
                  v-model="form.role_id"
                  :state="errors.length > 0 ? false:null"
                  name="role"
                  value-field="id"
                  text-field="display_name"
                  :placeholder="$t('Выберите роль')"
                  :options="roles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="isSalerRole"
              :label="$t('На какой склад')"
              label-for="warehouse_id"
            >
              <validation-provider
                #default="{ errors }"
                name="warehouse_id"
                rules="required"
              >
                <b-form-select
                  id="warehouse_id"
                  v-model="form.warehouse_id"
                  :state="errors.length > 0 ? false:null"
                  name="name"
                  value-field="id"
                  text-field="name_uz"
                  :placeholder="$t('На какой склад')"
                  :options="warehouses.data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <template v-if="isHWorker">
              <b-form-group
                :label="$t('Выберите область')"
                label-for="region_id"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Выберите область')"
                  rules="required"
                >
                  <b-form-select
                    id="region_id"
                    v-model="form.region_id"
                    :state="errors.length > 0 ? false:null"
                    name="name"
                    value-field="id"
                    text-field="name_uz"
                    :placeholder="$t('Выберите область')"
                    :options="regions.data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Выберите район')"
                label-for="city_id"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Выберите район')"
                  rules="required"
                >
                  <b-form-select
                    id="city_id"
                    v-model="form.city_id"
                    :state="errors.length > 0 ? false:null"
                    name="name"
                    value-field="id"
                    text-field="name_uz"
                    :placeholder="$t('Выберите район')"
                    :options="cities.data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Адрес')"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="form.address"
                    :state="errors.length > 3 ? false:null"
                    name="name"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Введите телефон')"
                label-for="v-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Введите телефон')"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend
                      is-text
                    >
                      +998
                    </b-input-group-prepend>

                    <b-form-input
                      id="v-phone"
                      v-model="form.phone"
                      v-mask="'#########'"
                      name="v-phone"
                      :state="errors.length > 3 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <b-form-group
              :label="$t('Статус')"
              label-for="status"
            >
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                :value="1"
                :unchecked-value="0"
                name="check-button"
                switch
                inline
              >
                {{ $t('Актив') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-for="user-new-password"
              :label="$t('Новый парол')"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="form.id?'':'required'"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="user-new-password"
                    v-model="form.password"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('Повторите новый пароль')"
            >
              <validation-provider
                #default="{ errors }"
                name="retype_password"
                :rules="form.id?'':'required|confirmed:password'"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="form.retype_password"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-form>
        </validation-observer>
        <!--  -->
        <b-row class="my-2">
          <!-- <b-col cols="12" md="12">
            <b-button size="sm" variant="outline-primary mr-1" class="link mb-2" @click="imageModal = true">
              <feather-icon icon="CameraIcon" size="12" />
              Изменить фото
            </b-button>
            <div v-if="mainPhoto" class="text-center">
              <el-image fluid class="product-img" :src="storageUrl + mainPhoto.url"
                :preview-src-list="previewSrcList" />
              <list-view v-if="product.photos && product.photos.length" class="w-100" :photos="previewSrcList" />
            </div>
            <div v-else>
              <b-img src="@/assets/images/avatars/product.jpg" :alt="`Image of ${product.name_uz}`" class="product-img"
                fluid />
            </div>
          </b-col> -->

          <b-col
            cols="12"
            md="12"
          >
            <p class="fs14">
              {{
                product.parent0_name_uz +
                  "/" +
                  product.parent1_name_uz +
                  "/" +
                  product.parent2_name_uz +
                  "/"
              }}
            </p>
            <h4>{{ product.name_uz }}</h4>
            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.price_dollar }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена продажа
              </span>
            </div>
            <!-- Price And Ratings -->
            <div
              v-if="!['marketer'].includes(user.role.name)"
              class="ecommerce-details-price d-flex flex-wrap mt-1"
            >
              <h4 class="item-price mr-1">
                ${{ product.cost_dollar }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена прихода
              </span>
            </div>
            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.price_dollar_installment }}
              </h4>
              <span class="unstyled-list list-inline pl-1 border-left">
                Цена рассрочка
              </span>
            </div>
            <hr>
            <div v-if="!isEdit">
              <b-button
                variant="outline-primary mr-1"
                class="float-right"
                @click="openEditor()"
              >
                <feather-icon
                  icon="EditIcon"
                  size="12"
                />
                Tahrirlash
              </b-button>
              <div
                v-for="(characters, index) in characteristicsInfoList"
                :key="index"
              >
                <div v-if="characters.is_description">
                  <h1>Tavsifi</h1>
                  <div
                    class="description-show mt-2"
                    v-html="characters.value"
                  />
                </div>
              </div>
              <hr>
              <h1>Xarakteristika</h1>
              <div
                v-for="(characters, index) in characteristicsInfoList"
                :key="index + 99"
              >
                <div v-if="!characters.is_description">
                  <div class="d-flex fs18 mt-1">
                    <b class="">
                      {{ characters.product_category_characteristic.name_uz }}:
                    </b>
                    <span class="ml-1">
                      {{ characters.value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Desciption
              v-if="isEdit"
              :characteristics-info-list="characteristicsInfoList"
              @save="saveDescription"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <ImageUpload
      v-if="imageModal"
      :uploaded-photos="product.photos"
      :product-name="product.name_uz"
      @close="closeUpload"
    />
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import ProductImageUpload from '@/components/ProductImageUpload.vue'
import { ref, computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BAlert,
} from 'bootstrap-vue'
import Desciption from './description.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BAlert,
    Desciption,
    ProductImageUpload,
    ValidationProvider,
    ValidationObserver,
    ImageUpload: () => import('@/components/modals/imagesUplad.vue'),
  },
  setup() {
    const product = ref(null)
    const mainPhoto = ref(null)
    const imageModal = ref(false)
    const isEdit = ref(false)
    const { route } = useRouter()
    const productId = 2754
    const fetchProduct = () => {
      store
        .dispatch('productCategory/show', {
          id: productId,
          relations: 'photos',
        })
        .then(response => {
          product.value = response.data
          if (response.data.photos && response.data.photos.length) {
            if (response.data.photos.find(p => p.is_main == 1)) {
              mainPhoto.value = response.data.photos.find(
                p => p.is_main == 1,
              )
            }
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            product.value = undefined
          }
        })
    }
    const selectedColor = ref(null)
    const characteristicsInfoList = ref(null)
    fetchProduct()
    const fetchDescription = () => {
      store
        .dispatch('productCategory/getCharacteristicsValue', {
          product_category_id: productId,
          relations: 'productCategoryCharacteristic',
        })
        .then(response => {
          characteristicsInfoList.value = response.data.data
          if (!characteristicsInfoList.value.length) {
            isEdit.value = true
          }
        })
    }
    fetchDescription()
    const storageUrl = `${process.env.VUE_APP_BASE_URL}storage/`
    const openEditor = () => {
      isEdit.value = true
    }
    const saveDescription = () => {
      fetchDescription()
      isEdit.value = false
    }
    const closeUpload = () => {
      imageModal.value = false
      fetchProduct()
    }
    const previewSrcList = computed(() => product.value?.photos.map(p => storageUrl + p.url))
    return {
      product,
      imageModal,
      isEdit,
      storageUrl,
      mainPhoto,
      selectedColor,
      characteristicsInfoList,
      previewSrcList,
      openEditor,
      saveDescription,
      closeUpload,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.description-show {
  border: 1px solid #eee;
  border-radius: 15px;
  padding: 10px;
}
</style>
