<template>
  <div>
    <span v-if="!isHasNotUploaded" class="btn btn-outline-warning upload-label float-right text-right"
      @click="addImage">
      <feather-icon icon="PlusSquareIcon" size="12" /> Rasmni qo'shish
    </span>

    <div class="d-flex justify-content-center">
      <div v-for="(photo, index) in imageData.photos" :key="index">
        <div v-loading="loads['loading' + index]" class="text-center m-2">
          <template v-if="photo.url">
            <img :src="storageUrl + photo.url" height="162" width="162"
              :class="photo.is_main ? 'img-uploaded-main' : 'img-uploaded'" @click="$refs.firstPassport[index].click()">
            <span class="upload-change-label" @click="$refs.firstPassport[index].click()">
              <feather-icon icon="RefreshCwIcon" size="12" />
              Rasmni o'zgartirish
              <template v-if="photo.is_main">(asosiy)</template>
            </span>
            <feather-icon v-if="imageData.photos.length > 1" icon="Trash2Icon" class="text-danger" size="18"
              @click="deleteImg(index)" />
          </template>
          <template v-else>
            <img src="@/assets/images/svg/fileuploader-placeholder.svg" class="img-placeholder" alt="image" width="162"
              @click="$refs.firstPassport[index].click()">
            <span class="upload-label" @click="$refs.firstPassport[index].click()">
              <feather-icon icon="PlusSquareIcon" size="12" /> Rasmni yuklash
            </span>
          </template>
          <input ref="firstPassport" accept="image/jpeg, image/jpg, image/png, image/webp" type="file" class="d-none"
            @change="(e) => onChangeFile(e, index, 'photo')">
        </div>
      </div>
    </div>

    <div class="mt-2 text-right">
      <b-button variant="primary" class="mr-1" @click="send">
        Yuklash
      </b-button>
    </div>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import axios from 'axios'
import { debounce } from 'debounce'

export default {
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    productName: {
      type: String,
      default: '',
    },
    uploadedPhotos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // imageModal: true,
      generatedImages: [],
      per_page: 10,
      page: 1,
      searchLoading: false,
      isGenerate: false,
      imageData: {
        product_category_id: null,
        photos: [
          {
            url: '',
            is_main: 1,
          },
        ],
      },
      loads: {
        loading0: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
    indexPhoto() {
      // const index = this.imageData.photos[0].url ? (this.imageData.photos.length - 1) : 0
      return (this.imageData.photos.length - 1)
    },
  },
  watch: {
  },
  mounted() {
    if (this.uploadedPhotos && this.uploadedPhotos.length) {
      this.imageData.photos = this.uploadedPhotos
    }
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      addPhotoProduct: 'productCategory/addPhotoProduct',
      updatePhotoProduct: 'productCategory/updatePhotoProduct',
    }),
    send() {
      this.imageData.product_category_id = this.model
        ? (this.model.category ? this.model.category.id : this.model.id)
        : this.$route.params.id
      this.imageData.photos.forEach((element, index) => {
        if (!element.url) {
          this.imageData.photos.splice(index, 1)
        }
      })
      this.addPhotoProduct(this.imageData).then(res => {
        showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
        this.$emit('close', true)
      })
    },
    addImage() {
      if (this.imageData.photos.length < 5 && !this.isHasNotUploaded) {
        this.imageData.photos.push({ url: '', is_main: 0 })
      }
    },
    async onChangeFile(event, index, directory) {
      if (event.target.files[0].size <= 15360000) {
        this.loads[`loading${index}`] = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', directory)
        this.fileUpload(fileData)
          .then(res => {
            this.imageData.photos[index].url = res.data.path
            if (this.imageData.photos.length < 5) {
              this.imageData.photos.push({ url: '', is_main: 0 })
            }
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loads[`loading${index}`] = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    deleteImg(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (
              this.imageData.photos.length > 1
              && this.imageData.photos[index].is_main
            ) {
              this.imageData.photos.splice(index, 1)
              this.imageData.photos[0].is_main = 1
              showToast('success', this.$t('Успешно удалено'))
            } else {
              this.imageData.photos.splice(index, 1)
              showToast('success', this.$t('Успешно удалено'))
            }
          }
        })
    },

  },
}
</script>

<style>
.generated_photo {
  width: 162px;
}
</style>
